////////////////////////////////////////////////
// NUCLEAR
////////////////////////////////////////////////

@function inv($c) {
  @return lighten(invert($c), 3%);
}

$black: #000;
$black-bis: #111111;
$black-ter: #242424;
$grey-darker: #353535;
$grey-dark: #494949;
$grey: #7a7a7a;
$grey-light: #b5b5b5;
$grey-lighter: #dbdbdb;

$white-ter: #f4f4f4;
$white-bis: #f9f9f9;
$white: #ffffff;

$black: inv($black);
$black-bis: inv($black-bis);
$black-ter: inv($black-ter);
$grey-darker: inv($grey-darker);
$grey-dark: inv($grey-dark);
$grey: inv($grey);
$grey-light: inv($grey-light);
$grey-lighter: inv($grey-lighter);
$white-ter: inv($white-ter);
$white-bis: inv($white-bis);
$white: inv($white);

$orange: #FF6E40;
$yellow: #d9ff00;
$green: #00ff48;
$turquoise: #FFD740;
$blue: #536DFE;
$purple: #bb86fc;
$red: #f90000;

$primary: $turquoise;
$danger: $red;
$warning: $orange;
$border: $primary;
$border-hover: $primary;

$body-background-color: $white;

$font-heading: "Varela Round", BlinkMacSystemFont, -apple-system, "Segoe UI",
  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", "Helvetica", "Arial", sans-serif;

$bulmaswatch-import-font: true !default;

$box-background: $white-bis;

// custom

$radius-small: 6px;
$radius: 9px;
$radius-large: 12px;
